<script setup>
    import {marked} from 'marked';
    import vPrism from 'o365.vue.directive.prism.js';
    import {loadCdnStyle} from 'o365.modules.helpers.js';
    import {ref, watch} from 'vue';
    import Prism from 'prismjs';
    //import DOMPurify from 'dom-purify';
    loadCdnStyle('marked-css');

    const props = defineProps({
        value: null,
        markedOptions: {
            type: Object,
            default: ()=>({}),
        },
        debounce: [Number, String],
        noSanitize: {
            type: Boolean,
            default: false
        },
        themeOverride: null,
    });

    function escapeHTML(html) {
        var escape = document.createElement('textarea');
        escape.textContent = html;
        return escape.innerHTML;
    }
    
    const codeRenderer = function(code, language){
        if (!Prism.languages[language]){
            console.warn(`Language not supported by prism.js: ${language}\nYou can find if a language can be supported here: https://prismjs.com\nCreate a WF to get languages added.\n\nCurrently supported languages: [${Object.keys(Prism.languages).join(', ')}]`);
        }

        if (!Object.keys(Prism.languages).includes(language)) {
            code = language + '\n' + code;
            language = ''
        }

        if (language == 'mermaid') {
            return '<div class="mermaid">' + code + '</div>';
        } else {
            if (!props.noSanitize) {
                return '<pre style="padding-top:30px;"><code class="language-'+language+'">' + code + '</code></pre>';
            }

            return '<pre style="padding-top:30px;"><code class="language-'+language+'">' + escapeHTML(code) + '</code></pre>';
        }
        
    };
    const renderer = new marked.Renderer();
    renderer.code = codeRenderer;
    
    const el = ref(null);

   
    marked.setOptions({
        renderer: renderer,
        gfm: true,
        async: true,
        ...props.markedOptions
    });
 
    const format = (pValue)=>{
        //const sanitizedValue = DOMPurify.sanitize(pValue ?? '');
        if (!pValue) { return; }
        let sanitizedValue;
        if (!props.noSanitize) {
            sanitizedValue = escapeHTML(pValue ?? '');
        } else {
            sanitizedValue = pValue;
        }
        return marked.parse(sanitizedValue)
    }

    // Optional debounce implementation when value is rapidly changing (code snippets editor for exampe)
    const asyncOutput = ref('');
    if (props.debounce) {
        let parseDebounce = null;
        watch(() => props.value, () => {
            if (parseDebounce) { window.clearTimeout(parseDebounce); }
            parseDebounce = window.setTimeout(() => {
                asyncOutput.value = format(props.value);
            }, +props.debounce);
        }, {
            immediate: true
        });
    }

</script>

<template>
    <div ref="el" v-html="debounce ? asyncOutput : format(value)"  v-prism="{ themeOverride: themeOverride }"></div>
</template>